import React from 'react';
import { Typography } from '@mui/material';
import {CardType, StatType} from "../../../types/card";
import {CardEffectFrameColor, getArtworkBorder, getStatBoxBackgroundColor, TextColor} from "../../../types/color";
import {getFontSize} from "../../../utils/fonts";

interface StatFrameProps {
    value: number;
    statType: StatType;
    scale: number;
}

const StatFrame: React.FC<StatFrameProps> = ({ value, statType, scale }) => (
    <Typography variant="h6" sx={{
        textAlign: 'center',
        backgroundColor: getStatBoxBackgroundColor(statType),
        borderRadius: `${0.8 * scale}rem`,
        color: TextColor.WHITE,
        padding: `${0.1 * scale}rem`,
        paddingTop: `${0.4 * scale}rem`,
        fontSize: getFontSize(8, scale),
        fontWeight: '700',
        width: `${7.07 * scale}rem`,
        height: `${4.5 * scale}rem`,
        border: `solid ${0.2 * scale}rem black`,
    }}>
        {value}
    </Typography>
);

export default StatFrame;
