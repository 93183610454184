export enum UpdateFrequencies {
    ONE_MINUTE = 60000,
    TWO_MINUTES = 120000,
    FIVE_MINUTES = 300000,
    TEN_MINUTES = 600000,
    TWENTY_MINUTES = 1200000,
    THIRTY_MINUTES = 1800000,
    ONE_HOUR = 3600000,
    TWO_HOURS = 7200000,
    SIX_HOURS = 21600000,
    TWELVE_HOURS = 43200000
}